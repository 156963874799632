<template>
  <div class="borrowingStatus">
    <div class="borrowingStatus-box">
      <div class="img"><img src="./img/res.png" alt="" /></div>
      <div class="txt">借物成功</div>
      <div class="btn" @click="returnIndex">返回</div>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
export default {
  name: "borrowingStatus",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {},
  methods: {
    returnIndex() {
      this.$router.push({
        name: "borrowing",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.borrowingStatus {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .borrowingStatus-box {
    padding-top: 242px;
    .img {
      width: 490px;
      height: 372px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .txt {
      text-align: center;
      margin: 44px auto 112px;
      font-size: 32px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 44px;
    }
    .btn {
      width: 530px;
      height: 70px;
      background: #8a95a6;
      border-radius: 18px;
      font-size: 28px;
      text-align: center;
      font-weight: 400;
      color: #ffffff;
      line-height: 70px;
      margin: 0 auto;
    }
  }
}
</style>
<style lang="less">
.borrowingStatus {
}
</style>
